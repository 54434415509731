<template>
    <div class="more">
        <div class="mlist" v-for="(li,index) in moreList" :key="index" @click="tuijian(li)">
            <div class="minage"><el-image :src="li.image"></el-image></div>
            <div>{{li.title}}</div>
        </div>
    </div>
</template>
<script>
import {filesub} from '../../utils/index'
export default {
  props: ['moreList'],

  data(){
      return {

      }
  },
  methods:{
     tuijian(item){
       window.open(item.link,'_blank')
    }
  }
}
</script>
<style scoped lang="scss">
  .more{
    overflow: hidden;
    padding-bottom: 56px;
    .mlist{
        text-align: center;
        float: left;
        width: 25%;
        cursor: pointer;
    }
    .minage{
        width: 120px;
        height: 120px;
        margin: 0 auto;
        margin-bottom: 15px;
    }
}
</style>
